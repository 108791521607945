import React from "react";
import { Container } from "react-bootstrap";
import { PolicyContainer } from "./style";

const PrivacyPolicy = () => {
      return (
            <>
                  <h1 className="d-none">World's Got Talent Privacy Policy</h1>
                  <Container>
                        <PolicyContainer>
                              <h3>World's Got Talent Privacy Policy</h3>
                              <p>
                                    This Privacy Policy explains how World's Got
                                    Talent and its affiliates (“ World's Got
                                    Talent Affiliates”) (collectively “ we” or “
                                    us”) collect, use, and share information
                                    from or about you when you use our websites,
                                    downloadable applications, browser
                                    extensions, interactive TV applications,
                                    voice-activated skills, other digital
                                    services, connected devices such as
                                    theme-park devices, and off-line services
                                    that link to this policy, or if you engage
                                    in business with us (“ World's Got Talent
                                    Services or Services”).
                              </p>
                              <p>
                                    This Privacy Policy applies to the World's
                                    Got Talent Services throughout the world.
                                    Any additional privacy terms that relate to
                                    specific World's Got Talent products or
                                    services should be read in conjunction with
                                    this Policy.
                              </p>
                              <p>
                                    As one of the world’s leading media and
                                    entertainment companies, we offer World's
                                    Got Talent Services through a wide range of
                                    businesses and under many different brands.
                                    The data controller of your personal
                                    information will be the World's Got Talent
                                    company which provides services to you or
                                    does business with you. You can find out
                                    more information about World's Got Talent,
                                    including a list of our World's Got Talent
                                    Affiliates, here .
                              </p>
                              <p>
                                    PLEASE READ THIS PRIVACY POLICY CAREFULLY TO
                                    UNDERSTAND HOW WE HANDLE YOUR INFORMATION.
                                    BY USING THE SERVICES, YOU ACKNOWLEDGE YOU
                                    HAVE READ AND UNDERSTAND THE PRIVACY POLICY
                                    AND THAT WE WILL COLLECT, USE AND SHARE YOUR
                                    INFORMATION AS SET FORTH BELOW.
                              </p>
                        </PolicyContainer>
                  </Container>
            </>
      );
};

export default PrivacyPolicy;
