import "./style.css";
import Header from "../layouts/header";
import "react-progress-2/main.css";
import Progress from "react-progress-2";
import { ThemeProvider } from "styled-components";
import { lightTheme } from "../styles/themes";
import { GlobalStyle } from "../styles/GlobalStyle";
import Footer from "../layouts/footer";
import Router from "../routers";
import { Suspense } from "react";
import ScrollToTop from "../components/ScrollToTop";

function App() {
      return (
            <>
                  <ThemeProvider theme={lightTheme}>
                        <Progress.Component
                              style={{ background: "#99999978", height: "5px" }}
                              thumbStyle={{
                                    background: "#E3B43B",
                                    height: "5px",
                              }}
                        />
                        <GlobalStyle />
                        <ScrollToTop />
                        <Header />
                        <Suspense fallback="Loading">
                              <Router />
                        </Suspense>
                        <Footer />
                  </ThemeProvider>
            </>
      );
}

export default App;
