import React from "react";
import { Container } from "react-bootstrap";
import BlogSection from "../BlogSection";
import BlogLists from "../../components/blogs/BlogLists";
import { blognews } from "../../data/blogdata";

const HomePage = () => {
      return (
            <>
                  <h1 className="d-none">
                        Exploring the Diverse Talents of World's Got Talent Show
                        Contestants
                  </h1>
                  <Container>
                        <div className="my-5">
                              <BlogLists blogs={blognews} />
                        </div>
                        <BlogSection />
                  </Container>
            </>
      );
};

export default HomePage;
