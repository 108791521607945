import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
      CareerContent,
      CareerIntern,
      CareerSummary,
      CareerTitle,
} from "../careers/style";

const Gallery = () => {
      return (
            <>
                  <h1 className="d-none">
                        We believe in the talent of our people
                  </h1>
                  <Container>
                        <Row>
                              <Col lg={12}>
                                    <CareerContent className="border-0">
                                          <CareerTitle>
                                                Early Gallery
                                          </CareerTitle>
                                          <CareerSummary>
                                                Whether you’re ready to land
                                                your dream internship or a
                                                full-time gig, we have the early
                                                career programs to help you
                                                succeed. Our competitive pre and
                                                post-grad initiatives provide
                                                real life experience and hands
                                                on training with the best in the
                                                business. Best of all, you’ll
                                                get an up-close look at our
                                                businesses and emerge with the
                                                skills and tools to take the
                                                next step in your career.
                                          </CareerSummary>
                                          <Row>
                                                <Col lg={4}>
                                                      <CareerIntern>
                                                            <img
                                                                  src="/trendings/011024_tsjffep_10.jpg"
                                                                  alt="Early Careers"
                                                                  width="100%"
                                                                  height="100%"
                                                            />
                                                      </CareerIntern>
                                                </Col>
                                                <Col lg={4}>
                                                      <CareerIntern>
                                                            <img
                                                                  src="/news/LAB_FullEp_011624_13.jpg"
                                                                  alt="Early Careers"
                                                                  width="100%"
                                                                  height="100%"
                                                            />
                                                      </CareerIntern>
                                                </Col>
                                                <Col lg={4}>
                                                      <CareerIntern>
                                                            <img
                                                                  src="/news/m9_hero-scaled.jpg"
                                                                  alt="Early Careers"
                                                                  width="100%"
                                                                  height="100%"
                                                            />
                                                      </CareerIntern>
                                                </Col>
                                                <Col lg={4}>
                                                      <CareerIntern>
                                                            <img
                                                                  src="/news/011624_tsjffep_6.jpg"
                                                                  alt="Early Careers"
                                                                  width="100%"
                                                                  height="100%"
                                                            />
                                                      </CareerIntern>
                                                </Col>
                                                <Col lg={4}>
                                                      <CareerIntern>
                                                            <img
                                                                  src="/news/CF_BarelyGone_Thumb_8.jpg"
                                                                  alt="Early Careers"
                                                                  width="100%"
                                                                  height="100%"
                                                            />
                                                      </CareerIntern>
                                                </Col>
                                                <Col lg={4}>
                                                      <CareerIntern>
                                                            <img
                                                                  src="/news/CMED_01_1.jpg"
                                                                  alt="Early Careers"
                                                                  width="100%"
                                                                  height="100%"
                                                            />
                                                      </CareerIntern>
                                                </Col>
                                          </Row>
                                    </CareerContent>
                              </Col>
                        </Row>
                  </Container>
            </>
      );
};

export default Gallery;
