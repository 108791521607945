import React from "react";
import {
      HeaderContainer,
      HeaderContent,
      NavLinkContainer,
} from "../../../../layouts/header/style";
import NavLinkItem from "../NavLinkItem";
import FacebookIcon from "../../../icons/Facebook";
import InstagramIcon from "../../../icons/Instagram";
import LinkedIn from "../../../icons/LinkedIn";
import { Container } from "react-bootstrap";

const SubHeader = () => {
      return (
            <>
                  <HeaderContainer>
                        <Container>
                              <HeaderContent>
                                    <div>Sunday, February 9th, 2024</div>
                                    <NavLinkContainer>
                                          <NavLinkItem
                                                icon={<FacebookIcon />}
                                                slug="https://facebook.com"
                                                title="World's Got Talent Official Facebook Page"
                                          />
                                          <NavLinkItem
                                                icon={<InstagramIcon />}
                                                slug="https://instagram.com"
                                                title="World's Got Talent Official Instagram Page"
                                          />
                                          <NavLinkItem
                                                icon={<LinkedIn />}
                                                slug="https://linkedin.com"
                                                title="World's Got Talent Official Linkedin Page"
                                          />
                                    </NavLinkContainer>
                              </HeaderContent>
                        </Container>
                  </HeaderContainer>
            </>
      );
};

export default SubHeader;
