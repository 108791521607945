export const lightTheme = {
      body: "#212529",
      primary: "#212529",
      secondary: "#E4B43C",
      white: "#FFFFFF",
      black: "#000000",
      hoverColor: "#8F23B3",
      darkPrimary: "#496092",
      fontFamily: "'Poppins', sans-serif",
};

export const DarkTheme = {
      body: "#000000",
      text: "#FCF6F4",
      fontFamily: "'Poppins', sans-serif",
      textRgba: "252, 246, 244",
      bodyRgba: "0,0,0",
};
