import React from "react";
import { Container, Row, Col, FormControl } from "react-bootstrap";
import { ContactDetail, ContactForm, ContactSocial } from "./style";
import {
      FacebookIcon,
      InstagramIcon,
      LinkedinIcon,
      TwitterIcon,
      YoutubeIcon,
} from "../../icons";
import { RegisterBanner } from "../../components/common";
import { FormButton } from "../faqs/style";

const ContactUs = () => {
      return (
            <>
                  <Container className="my-5">
                        <Row className="g-5">
                              <Col lg={8}>
                                    <h2>Have a question or feedback?</h2>
                                    <p>
                                          Ut euismod ultricies sollicitudin.
                                          Curabitur sed dapibus nulla. Nulla
                                          eget iaculis lectus. Mauris ac maximus
                                          neque. Nam in mauris quis libero
                                          sodales eleifend. Morbi varius, nulla
                                          sit amet rutrum elementum, est elit
                                          finibus tellus, ut tristique elit
                                          risus at metus. In ut odio libero, at
                                          vulputate urna. Nulla tristique mi a
                                          massa convallis cursus. Nulla eu mi
                                          magna. Etiam suscipit commodo gravida.
                                    </p>
                                    <ContactForm>
                                          <Row className="g-4">
                                                <Col lg={12}>
                                                      <FormControl
                                                            type="text"
                                                            placeholder="Your Name"
                                                      />
                                                </Col>
                                                <Col lg={6}>
                                                      <FormControl
                                                            type="email"
                                                            placeholder="Email Address"
                                                      />
                                                </Col>
                                                <Col lg={6}>
                                                      <FormControl
                                                            type="text"
                                                            placeholder="Phone No."
                                                      />
                                                </Col>
                                                <Col lg={12}>
                                                      <FormControl
                                                            type="text"
                                                            placeholder="Subject"
                                                      />
                                                </Col>
                                                <Col lg={12}>
                                                      <FormControl
                                                            placeholder="Your Message"
                                                            as="textarea"
                                                            rows={8}
                                                      />
                                                </Col>
                                                <Col lg={12}>
                                                      <FormButton>
                                                            Send your query
                                                      </FormButton>
                                                </Col>
                                          </Row>
                                    </ContactForm>
                              </Col>
                              <Col lg={4}>
                                    <h2>Details</h2>
                                    <ContactDetail>
                                          <li>
                                                <span>
                                                      <i class="fal fa-map-marker"></i>{" "}
                                                      Adress :
                                                </span>{" "}
                                                <a href="#">
                                                      USA 27TH Brooklyn NY
                                                </a>
                                          </li>
                                          <li>
                                                <span>
                                                      <i class="fal fa-phone"></i>{" "}
                                                      Phone :
                                                </span>{" "}
                                                <a href="#">+7(123)987654</a>
                                          </li>
                                          <li>
                                                <span>
                                                      <i class="fal fa-envelope"></i>{" "}
                                                      Mail :
                                                </span>{" "}
                                                <a href="#">
                                                      gmagnews@domain.com
                                                </a>
                                          </li>
                                    </ContactDetail>
                                    <ContactSocial>
                                          {/* <div>Follow Us</div> */}
                                          <ul>
                                                <li>
                                                      <a href="">
                                                            <FacebookIcon />
                                                      </a>
                                                </li>
                                                <li>
                                                      <a href="">
                                                            <InstagramIcon />
                                                      </a>
                                                </li>
                                                <li>
                                                      <a href="">
                                                            <YoutubeIcon />
                                                      </a>
                                                </li>
                                                <li>
                                                      <a href="">
                                                            <LinkedinIcon />
                                                      </a>
                                                </li>
                                                <li>
                                                      <a href="">
                                                            <TwitterIcon />
                                                      </a>
                                                </li>
                                          </ul>
                                    </ContactSocial>
                                    <RegisterBanner />
                              </Col>
                        </Row>
                  </Container>
            </>
      );
};

export default ContactUs;
