import { Link } from "react-router-dom";
import styled from "styled-components";

export const RegisterContainer = styled.div`
      position: relative;
      margin-top: 3rem;
      border-radius: 5px;

      img {
            width: 100%;
            border-radius: 5px;
      }
`;

export const RegisterContent = styled.div`
      position: absolute;
      bottom: 10px;
      padding: 20px;
`;

export const RegisterTitle = styled.div`
      color: white;
      font-size: 18px;
`;

export const RegisterButton = styled(Link)`
      padding: 10px 20px;
      color: ${(props) => props.theme.white};
      border-radius: 5px;
      font-size: 15px;
      transition: all 0.3s ease-in-out;
      background-color: ${(props) => props.theme.secondary};

      &:hover {
            background-color: ${(props) => props.theme.primary};
      }
`;
