import React, { useState } from "react";
import { MenuButton, MenuContent, MobileMenuContent } from "./style";
import Offcanvas from "react-bootstrap/Offcanvas";

const MobileMenu = () => {
      const [show, setShow] = useState(false);

      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);

      return (
            <>
                  <MenuButton onClick={handleShow}>
                        <i className="bx bx-menu"></i>
                  </MenuButton>

                  <MenuContent
                        className="mobile__menu"
                        show={show}
                        onHide={handleClose}
                  >
                        <Offcanvas.Header closeButton>
                              <Offcanvas.Title>
                                    <img
                                          src="/white-logo.png"
                                          alt="World's Got Talent Logo"
                                          width="100%"
                                          height="100%"
                                    />
                              </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                              <MobileMenuContent>
                                    <li>
                                          <a href="/">Home</a>
                                    </li>
                                    <li>
                                          <a href="/about-us">About Us</a>
                                    </li>
                                    <li>
                                          <a href="/category/singers">
                                                Singers
                                          </a>
                                    </li>
                                    <li>
                                          <a href="/category/dancers">
                                                Dancers
                                          </a>
                                    </li>
                                    <li>
                                          <a href="/category/acrobats">
                                                Acrobats
                                          </a>
                                    </li>
                                    <li>
                                          <a href="/category/comedians">
                                                Comedians
                                          </a>
                                    </li>
                                    <li>
                                          <a href="/category/magicins">
                                                Magicins
                                          </a>
                                    </li>
                                    <li>
                                          <a href="/category/musicians">
                                                Musicians
                                          </a>
                                    </li>
                                    <li>
                                          <a href="/contact-us">Contact Us</a>
                                    </li>
                              </MobileMenuContent>
                        </Offcanvas.Body>
                  </MenuContent>
            </>
      );
};

export default MobileMenu;
