import React from "react";
import { LinkItem } from "./style";
import { HiddenText } from "../../../common";

const NavLinkItem = ({ icon, slug, title, padding, lineheight, color }) => {
      return (
            <LinkItem
                  color={`${color ? color : "#222"}`}
                  padding={padding}
                  lineheight={lineheight}
            >
                  <a href={slug} aria-label={title}>
                        {icon ? (
                              <>
                                    {icon}
                                    <HiddenText>{title}</HiddenText>
                              </>
                        ) : (
                              title
                        )}
                  </a>
            </LinkItem>
      );
};

export default NavLinkItem;
