import { Link } from "react-router-dom";
import styled from "styled-components";

export const FooterContainer = styled.div`
      background-color: #1f2126;
      padding: 60px 10px 60px 10px;
      border-top: 4px solid #e4b43c;
      margin-top: 3rem;

      h3 {
            color: white;
            font-size: 18px;
            margin-bottom: 12px;
      }

      p {
            color: #f2f2f2;
            font-weight: 300;
      }

      ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                  min-width: max-content;

                  a {
                        color: #c4c4c4;
                        font-weight: 300;
                        transition: all 0.3s ease;
                        min-width: max-content;

                        &:hover {
                              color: ${(props) => props.theme.secondary};
                              margin-left: 10px;
                        }
                  }
            }
      }
`;

export const FooterLogo = styled(Link)`
      width: 160px;
      display: block;

      img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            padding: ${(props) => (props.padding ? props.padding : "0")};
      }

      @media screen and (max-width: 768px) {
            width: ${(props) => props.height || "160px"};
            padding: 10px 0;
      }
`;
export const FooterContent = styled.div`
      height: 100%;
      border-style: dashed;
      border-width: 0px 1px 0px 0px;
      border-color: #ffffff30;
      padding: 0px 30px 0px 30px;

      h3 {
            font-size: 18px;
            font-weight: 500;
      }
      li {
            padding: 5px 0;
            font-size: 16px;
      }
      &.last-column {
            border-right: 0;
      }
      &.first-column {
            padding: 0;
            display: flex;
            align-items: center;
      }

      @media screen and (max-width: 1000px) {
            &.last-column {
                  margin-top: 30px;
                  text-align: center;
            }
      }

      @media screen and (max-width: 768px) {
            border: 0;
            &.first-column {
                  margin-bottom: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding-left: 0;
                  padding-left: 0;

                  h3 {
                        min-width: max-content;
                  }
            }

            &.right-column {
                  padding-left: 0;
            }

            &.middle-column {
                  border-style: dashed;
                  border-width: 0px 1px 0px 0px;
                  border-color: #ffffff30;
                  padding-left: 0;

                  h3 {
                        min-width: max-content;
                  }
            }

            &.last-column {
                  padding: 0 20px;
            }
      }
`;
export const FooterCopyright = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #222;
      border-top: 4px solid #e4b43c;
      padding: 18px 0;
      font-weight: 300;

      a {
            color: ${(props) => props.theme.primary};
            text-decoration: underline !important;
            font-weight: 300;
            transition: all 0.3s ease-in-out;

            &:hover {
                  color: ${(props) => props.theme.secondary};
                  text-decoration: none !important;
            }
      }
`;

export const FooterCopyrightContainer = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 578px) {
            flex-direction: column;
      }
`;

export const SocialMedia = styled.ul`
      display: flex;
      list-style: none;
      padding: 0;
      gap: 10px;

      i {
            font-size: 24px;
      }

      li {
            a {
                  color: #c4c4c4 !important;

                  &:hover {
                        margin-left: 0 !important;
                        color: #f2f2f2 !important;
                  }
            }
      }

      @media screen and (max-width: 1000px) {
            justify-content: center;
      }
`;

export const SubscriptionWrapper = styled.div`
      input {
            padding: 9px 10px;
            background-color: #ffffff;
            outline: none;
      }

      button {
            margin-left: 7px;
            padding: 9px 10px;
            text-wrap: nowrap;
            background-color: ${(props) => props.theme.white};
            color: ${(props) => props.theme.primary};

            &:hover {
                  color: ${(props) => props.theme.white};
                  background-color: ${(props) => props.theme.secondary};
            }
      }
`;
