import React from "react";
import { BlogCol, BlogColContent, OverlayEffect } from "../style";
import { BlogCollectionRow } from "./style";

const BlogCollection = ({ blogs }) => {
      const first = blogs.slice(0, 2);
      const second = blogs.slice(2, 4);
      return (
            <>
                  <BlogCollectionRow className="g-3">
                        {first.map((blogItem, index) => (
                              <BlogCol lg="6" key={index}>
                                    <div className="position-relative ">
                                          <OverlayEffect>
                                                <img
                                                      src={blogItem.image}
                                                      alt={blogItem.title}
                                                      width="100%"
                                                      height="100%"
                                                />
                                          </OverlayEffect>
                                          <BlogColContent>
                                                <p>{blogItem.title}</p>
                                          </BlogColContent>
                                    </div>
                              </BlogCol>
                        ))}
                  </BlogCollectionRow>
                  <BlogCollectionRow className="g-3 mt-0">
                        {second.map((blogItem, index) => (
                              <BlogCol lg="6" className="last-col" key={index}>
                                    <div className="position-relative ">
                                          <OverlayEffect>
                                                <img
                                                      src={blogItem.image}
                                                      alt={blogItem.title}
                                                      width="100%"
                                                      height="100%"
                                                />
                                          </OverlayEffect>
                                          <BlogColContent>
                                                <p>{blogItem.title}</p>
                                          </BlogColContent>
                                    </div>
                              </BlogCol>
                        ))}
                  </BlogCollectionRow>
            </>
      );
};

export default BlogCollection;
