import React from "react";

const TwitterIcon = () => {
      return (
            <>
                  <i className="bx bxl-twitter"></i>
            </>
      );
};

export default TwitterIcon;
