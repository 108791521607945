import { Col } from "react-bootstrap";
import styled from "styled-components";
import { Swiper } from "swiper/react";

export const BlogSliderContainer = styled.div`
      display: flex;
      gap: 1.5rem;
      margin-bottom: 3rem;
`;

export const BlogSliderItem = styled.div`
      width: 50%;
      height: 364px;

      .swiper {
            height: 100%;
      }

      @media screen and (max-width: 1000px) {
            width: 100%;
      }

      @media screen and (max-width: 768px) {
            height: 280px;
      }
`;

export const SwiperBlog = styled(Swiper)`
      display: flex;
      justify-content: center;
      align-items: center;

      img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
      }
`;
export const BlogSliderContent = styled.div`
      padding: 15px;
      position: absolute;
      width: 100%;
      bottom: 2px;
      color: #fff;
      background: rgba(0, 0, 0, 0.5);
`;

export const BlogCol = styled(Col)`
      height: 100%;
      border-radius: 5px;

      &.last-col {
            border-bottom: 0;
      }
      img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
            border-bottom: 3px solid #e4b43c;
      }
`;

export const OverlayEffect = styled.div`
      height: 100%;
      position: relative;
      border-radius: 5px;

      &::after {
            content: "";
            background: rgba(0, 0, 0, 0.2);
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 5px;
      }
`;

export const BlogColContent = styled.div`
      bottom: 0;
      position: absolute;
      z-index: 10;
      color: #ffffff;
      padding: 0 15px;
      left: 0;
      right: 0;
`;
