import React from "react";

const CalenderIcon = ({ classes }) => {
  return (
    <>
      <i className={`bx bx-calendar`} style={{marginRight:classes}}></i>
    </>
  );
};

export default CalenderIcon;
