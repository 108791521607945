import { Form, Row } from "react-bootstrap";
import styled from "styled-components";

export const ContactForm = styled(Form)`
      .form-control {
            border: 1px solid #e1e1e1;
            background: #f9f9f9;
            width: 100%;
            padding: 10px 20px;
            border-radius: 4px;
            color: #000;
            -webkit-appearance: none;
            font-family: "Poppins", sans-serif;

            &:focus {
                  box-shadow: none;
            }
      }
`;

export const ContactDetail = styled.ul`
      background: #f9f9f9;
      border: 1px solid #eee;
      padding: 15px 20px;

      li {
            width: 100%;
            height: 45px;
            line-height: 45px;
            border-bottom: 1px dashed #ddd;
            font-weight: 500;
            list-style: none;

            span {
                  text-align: left;
                  min-width: 80px;
                  color: #666;
            }

            a {
                  margin-left: 20px;
                  color: #333;
                  text-align: left;
            }

            &:last-child {
                  border-bottom: 0;
            }
      }
`;

export const ContactSocial = styled.div`
      display: flex;
      align-items: center;

      ul {
            display: flex;
            align-items: center;
            margin: 0;
            padding: 0;
            gap: 10px;

            li {
                  list-style: none;

                  a {
                        width: 32px;
                        height: 32px;
                        line-height: 32px;
                        margin-left: 2px;
                        border-radius: 4px;
                        color: #fff;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        background-color: ${(props) => props.theme.primary};
                        transition: all 0.3s ease-in-out;

                        &:hover {
                              background-color: ${(props) =>
                                    props.theme.secondary};
                        }
                  }
            }
      }
`;
