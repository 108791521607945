import React from "react";
import { CustomHeading } from "./style";

const CommonHeading = ({ title, classes }) => {
      return (
            <CustomHeading className={classes}>
                  <span>{title}</span>
            </CustomHeading>
      );
};

export default CommonHeading;
