import React from "react";
import { SliderItemContainer, SliderItemContent } from "./style";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const BlogSlider = ({ blogs }) => {
      return (
            <>
                  <SliderItemContainer>
                        <img
                              src={blogs.image}
                              alt={blogs.title}
                              className="img-fluid"
                              width="100%"
                              height="100%"
                        />
                        <SliderItemContent>
                              <span> {blogs.date}</span>
                              <span> {blogs.author}</span>
                              <h4>{blogs.title}</h4>
                        </SliderItemContent>
                  </SliderItemContainer>
            </>
      );
};

export default BlogSlider;
