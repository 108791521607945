import React from "react";
import { Row, Col, FormControl, Container, FormLabel } from "react-bootstrap";
import {
      FacebookIcon,
      InstagramIcon,
      LinkedinIcon,
      TwitterIcon,
      YoutubeIcon,
} from "../../icons";
import { ContactDetail, ContactForm, ContactSocial } from "../contacts/style";
import { FormButton } from "../faqs/style";

const BecomeArtist = () => {
      return (
            <>
                  <Container className="my-5">
                        <Row className="g-5">
                              <Col lg={8}>
                                    <h2>Become a artist?</h2>
                                    <ContactForm>
                                          <Row className="g-4">
                                                <Col lg={6}>
                                                      <FormLabel>
                                                            First Name
                                                      </FormLabel>
                                                      <FormControl type="text" />
                                                </Col>
                                                <Col lg={6}>
                                                      <FormLabel>
                                                            Last Name
                                                      </FormLabel>
                                                      <FormControl type="text" />
                                                </Col>
                                                <Col lg={6}>
                                                      <FormLabel>Email Address</FormLabel>
                                                      <FormControl
                                                            type="email"
                                                            placeholder="Email Address"
                                                      />
                                                </Col>
                                                <Col lg={6}>
                                                      <FormLabel>Contact No.</FormLabel>
                                                      <FormControl
                                                            type="text"
                                                            placeholder="Phone No."
                                                      />
                                                </Col>
                                                <Col lg={6}>
                                                      <FormLabel>Password</FormLabel>
                                                      <FormControl
                                                            type="password"
                                                            placeholder="Password"
                                                      />
                                                </Col>
                                                <Col lg={6}>
                                                      <FormLabel>Confirm Password</FormLabel>
                                                      <FormControl
                                                            placeholder="Confirm Password"
                                                            type="password"
                                                      />
                                                </Col>
                                                <Col lg={12}>
                                                      <FormButton>
                                                            Join Now
                                                      </FormButton>
                                                </Col>
                                          </Row>
                                    </ContactForm>
                              </Col>
                              <Col lg={4}>
                                    <h2>Details</h2>
                                    <ContactDetail>
                                          <li>
                                                <span>
                                                      <i class="fal fa-map-marker"></i>{" "}
                                                      Adress :
                                                </span>{" "}
                                                <a href="#">
                                                      USA 27TH Brooklyn NY
                                                </a>
                                          </li>
                                          <li>
                                                <span>
                                                      <i class="fal fa-phone"></i>{" "}
                                                      Phone :
                                                </span>{" "}
                                                <a href="#">+7(123)987654</a>
                                          </li>
                                          <li>
                                                <span>
                                                      <i class="fal fa-envelope"></i>{" "}
                                                      Mail :
                                                </span>{" "}
                                                <a href="#">
                                                      gmagnews@domain.com
                                                </a>
                                          </li>
                                    </ContactDetail>
                                    <ContactSocial>
                                          {/* <div>Follow Us</div> */}
                                          <ul>
                                                <li>
                                                      <a href="">
                                                            <FacebookIcon />
                                                      </a>
                                                </li>
                                                <li>
                                                      <a href="">
                                                            <InstagramIcon />
                                                      </a>
                                                </li>
                                                <li>
                                                      <a href="">
                                                            <YoutubeIcon />
                                                      </a>
                                                </li>
                                                <li>
                                                      <a href="">
                                                            <LinkedinIcon />
                                                      </a>
                                                </li>
                                                <li>
                                                      <a href="">
                                                            <TwitterIcon />
                                                      </a>
                                                </li>
                                          </ul>
                                    </ContactSocial>
                              </Col>
                        </Row>
                  </Container>
            </>
      );
};

export default BecomeArtist;
