import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "../frontend/home";
import BlogDetail from "../frontend/blog-details";
import AboutPage from "../frontend/about";
import PrivacyPolicy from "../frontend/privacy-policy";
import TermsAndCondition from "../frontend/terms";
import Career from "../frontend/careers";
import Gallery from "../frontend/galleries";
import EventPage from "../frontend/events";
import FaqPage from "../frontend/faqs";
import ContactUs from "../frontend/contacts";
import BecomeArtist from "../frontend/become-a-artist";
import CategoryPage from "../frontend/category";

const Router = () => {
      return (
            <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/posts/:blogId" element={<BlogDetail />} />

                  {/* About Page */}
                  <Route path="/about-us" element={<AboutPage />} />

                  {/* Privacy Policy */}
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />

                  {/* Terms & Condition */}
                  <Route
                        path="/terms-and-condition"
                        element={<TermsAndCondition />}
                  />

                  {/* Category */}
                  <Route
                        path="/category/:categoryId"
                        element={<CategoryPage />}
                  />

                  {/* Career */}
                  <Route path="/career" element={<Career />} />

                  {/* Gallery */}
                  <Route path="/gallery" element={<Gallery />} />

                  {/* Events */}
                  <Route path="/events" element={<EventPage />} />

                  {/* Faqs */}
                  <Route path="/faqs" element={<FaqPage />} />

                  {/* Contact Us */}
                  <Route path="/contact-us" element={<ContactUs />} />

                  {/* Become a artist */}
                  <Route path="/become-a-artist" element={<BecomeArtist />} />
            </Routes>
      );
};

export default Router;
