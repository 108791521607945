import React from "react";

const InstagramIcon = () => {
      return (
            <>
                  <i className="bx bxl-instagram"></i>
            </>
      );
};

export default InstagramIcon;
