import styled from "styled-components";
import { Row } from "react-bootstrap";

export const AboutImage = styled.div`
      width: 100%;
      height: 100%;
      border-radius: 5px;

      img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
      }
`;

export const AboutRow = styled(Row)`
      @media screen and (max-width: 768px) {
            .col-lg-6 {
                  &:first-child {
                        order: 2;
                  }

                  &:last-child {
                        order: 1;
                        margin-bottom: 20px;
                  }
            }
      }
`;
