import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@300;400&display=swap');
* {
      padding: 0;
       margin: 0;
      box-sizing: border-box;
      font-family: 'Source Sans 3', sans-serif;
}

:root {
      --logo__color: #1d1d1b;
      --secondary__color: #eaaa02;
      --swiper-navigation-size: 31px !important;
}

a {
    text-decoration: none !important;
}
`;
