import React from "react";
import { Col, Row } from "react-bootstrap";
import BlogItemLayout from "../BlogItemLayout";

const BlogWidget = ({ blogs, position, direction }) => {
      const blogsTopThree = blogs.slice(-2);
      return (
            <>
                  <Row className={`flex-row-${direction ? "reverse" : ""}`}>
                        <Col lg={position ? "12" : "6"}>
                              <BlogItemLayout
                                    blog={blogs[0]}
                                    description={true}
                                    full="10px"
                              />
                        </Col>
                        <Col lg={position ? "12" : "6"}>
                              {blogsTopThree.map((blogItem, index) => (
                                    <BlogItemLayout
                                          blog={blogItem}
                                          key={index}
                                    />
                              ))}
                        </Col>
                  </Row>
            </>
      );
};

export default BlogWidget;
