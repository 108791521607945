import styled from "styled-components";

export const BannerContainer = styled.div`
      border-radius: 5px;

      img {
            border-radius: 5px;
            object-fit: contain;
      }

      @media screen and (max-width: 768px) {
            height: 120px;

            img {
                  height: 120px;
                  object-fit: cover;
                  object-position: left;
            }
      }
`;
