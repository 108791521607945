import styled from "styled-components";

export const BlogContainer = styled.div`
      ul {
            padding-left: 0;
            margin-top: 0;
      }
      li {
            list-style: none;
      }
      margin-bottom: ${(props) =>
            props.marginBottom ? props.marginBottom : ""};
      figure {
            margin-bottom: 0;
            margin-right: ${(props) => (props.margin ? props.margin : "0px")};
            width: ${(props) => (props.size ? props.size : "100%")};
      }
      img {
            width: 100%;
            height: 100%;
            object-fit: cover;
      }
`;

export const BlogContent = styled.div`
      span {
            font-size: 14px;
            font-weight: 300;
            color: #aaa;
            &:nth-child(1) {
                  margin-right: 14px;
            }
      }
      p {
            font-size: 16px;
            display: -webkit-box;

            -webkit-box-orient: vertical;
            text-align: justify;
            overflow: hidden;
            -webkit-line-clamp: ${(props) =>
                  props.lineClamp ? props.lineClamp : "2"};
            text-overflow: ellipsis;
      }
      h3 {
            margin-top: 8px;
            font-weight: 400;
            font-size: 16px;

            a {
                  color: ${(props) => props.theme.primary};
            }
      }
      margin-top: ${(props) => (props.marginTop ? props.marginTop : "0px")};
      width: ${(props) => (props.size ? props.size : "100%")};
`;
