import styled from "styled-components";

export const EventDate = styled.div`
      color: rgba(33, 37, 41, 0.75);
      margin-top: 10px;
`;

export const EventTitle = styled.div`
      font-size: 18px;
`;
