import styled from "styled-components";

export const LinkItem = styled.li`
      list-style: none;
      margin-bottom: 0;
      padding-left: 0;
      transition: all 0.3s ease-in-out;

      &:hover {
            background-color: ${(props) =>
                  props.color !== "#222" ? "transparent" : "#e4b43c"};
      }

      svg {
            width: 20px;
            height: 20px;
            fill: #1f2126;
      }

      i {
            color: ${(props) =>
                  props.color ? props.color : props.theme.white};

            &:hover {
                  color: ${(props) => props.theme.white};
            }
      }
      a {
            display: inline-block;
            line-height: ${(props) =>
                  props.lineheight ? props.lineheight : "49px"};
            padding: ${(props) => (props.padding ? props.padding : "0 10px")};
            color: ${(props) =>
                  props.color ? props.color : props.theme.white};

            &:hover {
                  color: ${(props) => props.theme.white};
            }
      }
`;
