import React from "react";
import {
      FooterContainer,
      FooterContent,
      FooterCopyright,
      FooterCopyrightContainer,
      FooterLogo,
      SocialMedia,
      SubscriptionWrapper,
} from "./style";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { HiddenText } from "../../components/common";

const Footer = () => {
      const footerData = [
            {
                  title: "Quick Links",
                  items: [
                        {
                              label: "Events",
                              slug: "/events",
                        },
                        {
                              label: "Gallery",
                              slug: "/gallery",
                        },
                        {
                              label: "Career",
                              slug: "/career",
                        },
                  ],
            },
            {
                  title: "Useful Links",
                  items: [
                        {
                              label: "Become a artist",
                              slug: "/become-a-artist",
                        },
                        {
                              label: "Terms & Condition",
                              slug: "/terms-and-condition",
                        },
                        {
                              label: "Privacy Policy",
                              slug: "/privacy-policy",
                        },
                  ],
            },
      ];
      return (
            <>
                  <FooterContainer>
                        <Container>
                              <Row>
                                    <Col lg="2" md={4}>
                                          <FooterContent className="first-column">
                                                <FooterLogo>
                                                      <img
                                                            src="/white-logo.png"
                                                            alt="World's Got Talent Logo"
                                                            width="100%"
                                                            height="100%"
                                                      />
                                                </FooterLogo>
                                          </FooterContent>
                                    </Col>
                                    {footerData.map((item, index) => (
                                          <Col
                                                lg="3"
                                                md={4}
                                                sm="6"
                                                key={index}
                                                className="col-6"
                                          >
                                                <FooterContent
                                                      className={
                                                            index === 0
                                                                  ? "middle-column"
                                                                  : "right-column"
                                                      }
                                                >
                                                      <h3>{item.title}</h3>
                                                      {item.items.map(
                                                            (
                                                                  dataItem,
                                                                  dataIndex
                                                            ) => (
                                                                  <ul
                                                                        key={
                                                                              dataIndex
                                                                        }
                                                                  >
                                                                        <li>
                                                                              <Link
                                                                                    to={
                                                                                          dataItem.slug
                                                                                    }
                                                                              >
                                                                                    {
                                                                                          dataItem.label
                                                                                    }
                                                                              </Link>
                                                                        </li>
                                                                  </ul>
                                                            )
                                                      )}
                                                </FooterContent>
                                          </Col>
                                    ))}
                                    <Col lg="4" md={12}>
                                          <FooterContent className="last-column">
                                                <h3>
                                                      <label htmlFor="subscribe">
                                                            All the day's
                                                            headlines and
                                                            highlights from
                                                            World Got Talent,
                                                      </label>
                                                </h3>
                                                <SubscriptionWrapper>
                                                      <div className="mt-4 d-flex  ">
                                                            <input
                                                                  type="email"
                                                                  id="subscribe"
                                                                  className="form-control"
                                                            />
                                                            <button className="btn ">
                                                                  Sign Up
                                                            </button>
                                                      </div>
                                                </SubscriptionWrapper>
                                                <div className="mt-3">
                                                      <SocialMedia>
                                                            <li>
                                                                  <Link to="https://facebook.com">
                                                                        <HiddenText>
                                                                              World's
                                                                              Got
                                                                              Talent
                                                                              Officail
                                                                              Facebook
                                                                              Page
                                                                        </HiddenText>
                                                                        <i className="bx bxl-facebook"></i>
                                                                  </Link>
                                                            </li>
                                                            <li>
                                                                  <Link to="https://instagram.com">
                                                                        <HiddenText>
                                                                              World's
                                                                              Got
                                                                              Talent
                                                                              Officail
                                                                              Instagram
                                                                              Page
                                                                        </HiddenText>
                                                                        <i className="bx bxl-instagram"></i>
                                                                  </Link>
                                                            </li>
                                                            <li>
                                                                  <Link to="https://youtube.com">
                                                                        <HiddenText>
                                                                              World's
                                                                              Got
                                                                              Talent
                                                                              Officail
                                                                              Youtube
                                                                              Page
                                                                        </HiddenText>
                                                                        <i className="bx bxl-youtube"></i>
                                                                  </Link>
                                                            </li>
                                                            <li>
                                                                  <Link to="https://tiktok.com">
                                                                        <HiddenText>
                                                                              World's
                                                                              Got
                                                                              Talent
                                                                              Officail
                                                                              Tiktok
                                                                              Page
                                                                        </HiddenText>
                                                                        <i className="bx bxl-tiktok"></i>
                                                                  </Link>
                                                            </li>
                                                      </SocialMedia>
                                                </div>
                                          </FooterContent>
                                    </Col>
                              </Row>
                        </Container>
                  </FooterContainer>
                  <FooterCopyright>
                        <Container>
                              <FooterCopyrightContainer>
                                    <div>
                                          Copyright © 2024 by{" "}
                                          <Link to="/">
                                                World's Got Talent.
                                          </Link>
                                    </div>
                                    <div>
                                          Powered by:{" "}
                                          <Link
                                                to="https://infinityinfosys.com"
                                                target="_blank"
                                          >
                                                Infinity Infosys
                                          </Link>
                                    </div>
                              </FooterCopyrightContainer>
                        </Container>
                  </FooterCopyright>
            </>
      );
};

export default Footer;
