import React from "react";

const LinkedinIcon = () => {
      return (
            <>
                  <i className="bx bxl-linkedin"></i>
            </>
      );
};

export default LinkedinIcon;
