import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
      CareerContent,
      CareerIntern,
      CareerSummary,
      CareerTitle,
      EventItem,
} from "../careers/style";
import { EventDate, EventTitle } from "./style";

const EventPage = () => {
      return (
            <>
                  <h1 className="d-none">
                        We believe in the talent of our people
                  </h1>
                  <Container>
                        <Row>
                              <Col lg={12}>
                                    <CareerContent className="border-0">
                                          <CareerTitle>
                                                Create the extraordinary Events
                                          </CareerTitle>
                                          <CareerSummary>
                                                We create world-class content,
                                                which we distribute across our
                                                portfolio of film, television,
                                                and streaming, and bring to life
                                                through our theme parks and
                                                consumer experiences. We own and
                                                operate leading entertainment
                                                and news brands, including
                                                dancers, musicians, comedians,
                                                singers, our premium
                                                ad-supported streaming service.
                                                We produce and distribute
                                                premier filmed entertainment and
                                                programming through Universal
                                                Filmed Entertainment Group and
                                                Universal Studio Group, and have
                                                world-renowned theme parks and
                                                attractions through Universal
                                                Destinations & Experiences.
                                                World's Got Talent is a
                                                subsidiary of Comcast
                                                Corporation.
                                          </CareerSummary>
                                          <Row>
                                                <Col lg={4}>
                                                      <EventItem>
                                                            <CareerIntern margin="0">
                                                                  <img
                                                                        src="/trendings/011024_tsjffep_10.jpg"
                                                                        alt="Early Careers"
                                                                        width="100%"
                                                                        height="100%"
                                                                  />
                                                            </CareerIntern>
                                                            <EventDate>
                                                                  1st May, 2024
                                                            </EventDate>
                                                            <EventTitle>
                                                                  We create
                                                                  world-class
                                                                  content
                                                            </EventTitle>
                                                      </EventItem>
                                                </Col>
                                                <Col lg={4}>
                                                      <EventItem>
                                                            <CareerIntern margin="0">
                                                                  <img
                                                                        src="/news/LAB_FullEp_011624_13.jpg"
                                                                        alt="Early Careers"
                                                                        width="100%"
                                                                        height="100%"
                                                                  />
                                                            </CareerIntern>
                                                            <EventDate>
                                                                  13th April,
                                                                  2024
                                                            </EventDate>
                                                            <EventTitle>
                                                                  We distribute
                                                                  across our
                                                                  portfolio of
                                                                  film
                                                            </EventTitle>
                                                      </EventItem>
                                                </Col>
                                                <Col lg={4}>
                                                      <EventItem>
                                                            <CareerIntern margin="0">
                                                                  <img
                                                                        src="/news/m9_hero-scaled.jpg"
                                                                        alt="Early Careers"
                                                                        width="100%"
                                                                        height="100%"
                                                                  />
                                                            </CareerIntern>
                                                            <EventDate>
                                                                  25th April,
                                                                  2024
                                                            </EventDate>
                                                            <EventTitle>
                                                                  Life through
                                                                  our theme
                                                                  parks and
                                                                  consumer
                                                                  experiences
                                                            </EventTitle>
                                                      </EventItem>
                                                </Col>
                                                <Col lg={4}>
                                                      <EventItem>
                                                            <CareerIntern margin="0">
                                                                  <img
                                                                        src="/news/011624_tsjffep_6.jpg"
                                                                        alt="Early Careers"
                                                                        width="100%"
                                                                        height="100%"
                                                                  />
                                                            </CareerIntern>
                                                            <EventDate>
                                                                  13th April,
                                                                  2024
                                                            </EventDate>
                                                            <EventTitle>
                                                                  We own and
                                                                  operate
                                                                  leading
                                                                  entertainment
                                                                  and news
                                                                  brands
                                                            </EventTitle>
                                                      </EventItem>
                                                </Col>
                                                <Col lg={4}>
                                                      <EventItem>
                                                            <CareerIntern margin="0">
                                                                  <img
                                                                        src="/news/CF_BarelyGone_Thumb_8.jpg"
                                                                        alt="Early Careers"
                                                                        width="100%"
                                                                        height="100%"
                                                                  />
                                                            </CareerIntern>
                                                            <EventDate>
                                                                  21st June,
                                                                  2024
                                                            </EventDate>
                                                            <EventTitle>
                                                                  We produce and
                                                                  distribute
                                                                  premier filmed
                                                                  entertainment
                                                                  and
                                                                  programming
                                                            </EventTitle>
                                                      </EventItem>
                                                </Col>
                                                <Col lg={4}>
                                                      <EventItem>
                                                            <CareerIntern margin="0">
                                                                  <img
                                                                        src="/news/CMED_01_1.jpg"
                                                                        alt="Early Careers"
                                                                        width="100%"
                                                                        height="100%"
                                                                  />
                                                            </CareerIntern>
                                                            <EventDate>
                                                                  24th July,
                                                                  2024
                                                            </EventDate>
                                                            <EventTitle>
                                                                  Universal
                                                                  Filmed
                                                                  Entertainment
                                                                  Group and
                                                                  Universal
                                                                  Studio
                                                            </EventTitle>
                                                      </EventItem>
                                                </Col>
                                          </Row>
                                    </CareerContent>
                              </Col>
                        </Row>
                  </Container>
            </>
      );
};

export default EventPage;
