import styled from "styled-components";

export const BlogDetailContainer = styled.div``;

export const BlogDetailTitle = styled.h1`
      font-size: 18px;
      font-weight: 600;
`;

export const BlogDetailContent = styled.div`
      padding: 10px;

      blockquote {
            background-color: #f2f2f2;
            padding: 2rem;
            border-radius: 5px;
            text-align: center;
            color: ${(props) => props.theme.primary};
      }

      img {
            max-width: 100%;
            border-radius: 5px;
            margin-bottom: 15px;
            width: 100%;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
            font-size: 18px;
            font-weight: 600;
      }

      ul {
            li {
                  color: ${(props) => props.theme.primary};
            }
      }

      p {
            color: ${(props) => props.theme.primary};
      }
`;
export const BlogDetailImage = styled.div`
      height: 482px;
      border-radius: 5px;

      img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
      }
`;
