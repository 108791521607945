import React from "react";
import {
      BlogItemContainer,
      BlogItemContent,
      BlogItemImage,
      BlogItemTitle,
} from "./style";
import { Link } from "react-router-dom";
import CalenderIcon from "../../icons/Calender";

const BlogItemLayout = ({ blog, full, description, height }) => {
      return (
            <>
                  <BlogItemContainer full={full}>
                        <BlogItemImage full={full} height={height}>
                              <Link to={`/posts/${blog.slug}`}>
                                    <img
                                          src={blog.image}
                                          alt={blog.title}
                                          width="100%"
                                          height="100%"
                                    />
                              </Link>
                        </BlogItemImage>
                        <BlogItemContent full={full}>
                              {!description && (
                                    <span>
                                          <CalenderIcon classes={"5px"} />
                                          {blog.date}
                                    </span>
                              )}
                              <BlogItemTitle to={`/posts/details`}>
                                    {blog.title}
                              </BlogItemTitle>
                              {description && (
                                    <span>
                                          <CalenderIcon classes={"5px"} />
                                          {blog.date}
                                    </span>
                              )}
                              {description && <p>{blog.description}</p>}
                        </BlogItemContent>
                  </BlogItemContainer>
            </>
      );
};

export default BlogItemLayout;
