import React from "react";
import { Container } from "react-bootstrap";
import { PolicyContainer } from "../privacy-policy/style";

const TermsAndCondition = () => {
      return (
            <>
                  <h1 className="d-none">
                        Effective Date October 28, 2022; Updated December 7,
                  </h1>
                  <Container>
                        <PolicyContainer>
                              <h3>
                                    Effective Date October 28, 2022; Updated
                                    December 7,
                              </h3>
                              <p>
                                    Welcome to the Terms of Service (the “TOS”)
                                    for our websites, software, mobile
                                    applications, content, games, interactive
                                    TV, or other products and online services
                                    (including all Content therein, the “World's
                                    Got Talent Services”). The World's Got
                                    Talent Services are made available to you by
                                    World's Got Talent, and its affiliated
                                    companies (including successors & assigns,
                                    “World's Got Talent,” “we,” “us” or “our”).
                              </p>
                              <p>
                                    These TOS set out the agreement between you
                                    and World's Got Talent regarding how you can
                                    use the World's Got Talent Services, and
                                    what responsibilities you and World's Got
                                    Talent have to each other. These TOS contain
                                    important information regarding your legal
                                    rights.
                              </p>
                              <p>
                                    <strong>
                                          BY USING THE World's Got Talent
                                          SERVICES, YOU ACCEPT AND AGREE TO BE
                                          BOUND AND ABIDE BY THESE TOS AND OUR
                                          PRIVACY POLICY
                                    </strong>
                                    , which describes our privacy practices in
                                    detail and is incorporated herein by
                                    reference. Please read them carefully.
                              </p>
                              <h3>General</h3>
                              <p>
                                    <strong>Acceptance of the TOS</strong>{" "}
                                    <br /> You may access the World's Got Talent
                                    Services in accordance with the TOS. You
                                    represent that you have read, understood,
                                    and agree to be bound by these TOS in
                                    connection with your access to and/or use of
                                    any of the World's Got Talent Services. If
                                    you do not agree to these TOS, you may not
                                    access or use the World's Got Talent
                                    Services. By using the World's Got Talent
                                    Services, you will be deemed to have agreed
                                    to the TOS.
                              </p>
                              <p>
                                    <strong>Eligibility</strong> <br />
                                    <p>
                                          The World's Got Talent Services are
                                          for users who are 18 years of age or
                                          older and reside in the United States
                                          (including its territories and
                                          possessions), or those outside the
                                          United States who, to the maximum
                                          extent permissible under applicable
                                          law, consent to use the World's Got
                                          Talent Services in accordance with
                                          U.S. laws and these TOS. Parental
                                          control protections (such as computer
                                          hardware, software, or ﬁltering
                                          services) are commercially available
                                          that may assist you in limiting access
                                          to material that is harmful to minors.
                                          Information regarding providers of
                                          such protections is available at the
                                          Electronic Frontier Foundation
                                          website, http://www.eff.org.
                                    </p>
                                    <p>
                                          By accessing or using the World's Got
                                          Talent Services, you represent and
                                          warrant that (i) you are not located
                                          in a country that is subject to a U.S.
                                          government embargo (e.g., Crimea,
                                          Cuba, Iran, North Korea or Syria); and
                                          (ii) your access to and use of the
                                          World's Got Talent Services will
                                          comply with the United States export
                                          control and economic sanctions
                                          requirements..
                                    </p>
                              </p>
                              <p>
                                    <strong>Supplemental terms</strong> <br />
                                    <p>
                                          Supplemental terms and conditions may
                                          apply to some World's Got Talent
                                          Services, such as sweepstakes,
                                          subscriptions or online purchases, and
                                          are deemed part of these TOS
                                          (“Supplemental Terms”). Any
                                          Supplemental Terms are in addition to
                                          these TOS and, to the extent there is
                                          a conﬂict between the Supplemental
                                          Terms and these TOS, the Supplemental
                                          Terms will prevail with respect to
                                          such conﬂict. If you do not agree to
                                          the applicable Supplemental Terms, you
                                          may not use the World's Got Talent
                                          Services to which they apply
                                    </p>
                              </p>
                        </PolicyContainer>
                  </Container>
            </>
      );
};

export default TermsAndCondition;
