import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
      CareerContent,
      CareerIntern,
      CareerItem,
      CareerSummary,
      CareerTitle,
} from "./style";

const Career = () => {
      return (
            <>
                  <h1 className="d-none">
                        We believe in the talent of our people
                  </h1>
                  <Container>
                        <Row>
                              <Col lg={12}>
                                    <CareerContent>
                                          <CareerTitle>
                                                Working at World's Got Talent
                                          </CareerTitle>
                                          <CareerSummary>
                                                We believe in the talent of our
                                                people. It's our passion and
                                                commitment to excellence that
                                                drives World's Got Talent's vast
                                                portfolio of brands to succeed.
                                                From broadcast and cable
                                                networks, news and sports
                                                platforms, to film,
                                                world-renowned theme parks and a
                                                diverse suite of digital
                                                properties, we take pride in all
                                                that we do and all that we
                                                represent. It's what makes us
                                                uniquely World's Got Talent.
                                          </CareerSummary>
                                    </CareerContent>
                              </Col>
                              <Col lg={12}>
                                    <CareerContent>
                                          <CareerTitle>
                                                Early Careers
                                          </CareerTitle>
                                          <CareerSummary>
                                                Whether you’re ready to land
                                                your dream internship or a
                                                full-time gig, we have the early
                                                career programs to help you
                                                succeed. Our competitive pre and
                                                post-grad initiatives provide
                                                real life experience and hands
                                                on training with the best in the
                                                business. Best of all, you’ll
                                                get an up-close look at our
                                                businesses and emerge with the
                                                skills and tools to take the
                                                next step in your career.
                                          </CareerSummary>
                                          <Row>
                                                <Col lg={4}>
                                                      <CareerIntern>
                                                            <img
                                                                  src="worlds-got-talent.png"
                                                                  alt="Early Careers"
                                                                  width="100%"
                                                                  height="100%"
                                                            />
                                                      </CareerIntern>
                                                </Col>
                                          </Row>
                                    </CareerContent>
                              </Col>
                              <Col lg={12}>
                                    <CareerContent className="border-0">
                                          <CareerTitle>
                                                Professional Careers
                                          </CareerTitle>
                                          <CareerSummary>
                                                There's so many opportunities to
                                                work across one of our iconic
                                                brands. This is your chance to
                                                be the ultimate fan. Work for
                                                your favorite news program,
                                                sports network, late night talk
                                                show, telenovela, movie
                                                franchise and more. Check out
                                                the open positions in your
                                                specialty below.
                                          </CareerSummary>
                                    </CareerContent>
                              </Col>
                              <Col lg={12}>
                                    <Row className="g-4">
                                          <Col lg={3}>
                                                <CareerItem>
                                                      <span>
                                                            Accounting/Auditing
                                                      </span>
                                                </CareerItem>
                                          </Col>
                                          <Col lg={3}>
                                                <CareerItem>
                                                      <span>
                                                            Administrative
                                                      </span>
                                                </CareerItem>
                                          </Col>
                                          <Col lg={3}>
                                                <CareerItem>
                                                      <span>Advertising</span>
                                                </CareerItem>
                                          </Col>
                                          <Col lg={3}>
                                                <CareerItem>
                                                      <span>Analyst</span>
                                                </CareerItem>
                                          </Col>
                                          <Col lg={3}>
                                                <CareerItem>
                                                      <span>Art/Creative</span>
                                                </CareerItem>
                                          </Col>
                                          <Col lg={3}>
                                                <CareerItem>
                                                      <span>
                                                            Business Development
                                                      </span>
                                                </CareerItem>
                                          </Col>
                                    </Row>
                              </Col>
                        </Row>
                  </Container>
            </>
      );
};

export default Career;
