import React from "react";
import { AboutImage, AboutRow } from "./style";
import { Container, Row, Col } from "react-bootstrap";

const AboutPage = () => {
      return (
            <>
                  <Container className="my-5">
                        <AboutRow>
                              <Col lg={6}>
                                    <h2>Our Story</h2>

                                    <p>
                                          Aliquam luctus tempor orci sit amet
                                          finibus. Duis porttitor, ex ac
                                          molestie consectetur, metus tellus
                                          venenatis velit, et interdum enim
                                          lorem a purus. Fusce scelerisque,
                                          massa vitae rhoncus elementum, enim
                                          acinia pharetra?
                                    </p>
                                    <p>
                                          Lorem ipsum dolor sit amet,
                                          consectetur adipiscing elit. Proin
                                          ornare sem sed quam tempus aliquet
                                          vitae eget dolor. Proin eu ultrices
                                          libero. Curabitur vulputate vestibulum
                                          elementum. Suspendisse id neque a nibh
                                          mollis blandit. Quisque varius eros ac
                                          purus dignissim. Proin eu ultrices
                                          libero.
                                    </p>
                                    <p>
                                          Lorem ipsum dolor sit amet,
                                          consectetur adipiscing elit. Proin
                                          ornare sem sed quam tempus aliquet
                                          vitae eget dolor. Proin eu ultrices
                                          libero. Curabitur vulputate vestibulum
                                          elementum. Suspendisse id neque a nibh
                                          mollis blandit. Quisque varius eros ac
                                          purus dignissim. Proin eu ultrices
                                          libero.
                                    </p>
                              </Col>
                              <Col lg={6}>
                                    <AboutImage>
                                          <img src="/news/6.jpg" alt="" />
                                    </AboutImage>
                              </Col>
                        </AboutRow>
                  </Container>
            </>
      );
};

export default AboutPage;
