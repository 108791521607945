import React from "react";
import {
      HeaderContainer,
      HeaderContent,
      NavLinkContainer,
} from "../../../../layouts/header/style";
import NavLinkItem from "../NavLinkItem";
import SearchIcon from "../../../icons/Search";
import { Container } from "react-bootstrap";

const NavBar = () => {
      return (
            <nav>
                  <HeaderContainer border="4px solid">
                        <Container>
                              <HeaderContent>
                                    <NavLinkContainer>
                                          <NavLinkItem
                                                title={"About us"}
                                                slug={"/about-us"}
                                                padding={"0 15px"}
                                          />

                                          <NavLinkItem
                                                title={"Contact Us"}
                                                slug={"/contact-us"}
                                                padding={"0 15px"}
                                          />
                                          <NavLinkItem
                                                title={"Faqs"}
                                                slug={"/faqs"}
                                                padding={"0 15px"}
                                          />
                                          {/* <NavLinkItem
                                                title={"Login"}
                                                slug={"/login"}
                                                padding={"0 15px"}
                                          /> */}
                                    </NavLinkContainer>
                                    <SearchIcon fontSize={"24px"} />
                              </HeaderContent>
                        </Container>
                  </HeaderContainer>
            </nav>
      );
};

export default NavBar;
