import React from "react";
import { blognews, entertainment, fashion } from "../../data/blogdata";
import BlogWidget from "../../components/blogs/BlogWidget";
import { Col, Row } from "react-bootstrap";
import BlogWidgetLeft from "../../components/blogs/BlogWidget/BlogWidgetLeft";
import CommonHeading from "../../components/CommonHeading";
import { CustomSticky } from "./style";
import BlogLayout from "../../components/blogs/BlogLayout";
import BlogItemLayout from "../../components/blogs/BlogItemLayout";
import { BannerImage, RegisterBanner } from "../../components/common";

const BlogSection = () => {
      return (
            <>
                  <Row>
                        <Col lg="8">
                              <CommonHeading classes="mb-3" title={"Dancers"} />
                              <BlogLayout
                                    blogs={entertainment}
                                    description={true}
                              />
                              <BannerImage
                                    image="/banners/Group 7.png"
                                    title="Banner"
                              />
                              <CommonHeading
                                    classes="my-3 mt-4"
                                    title={"Singers"}
                              />
                              <Row>
                                    {blognews.map((blogItem, index) => (
                                          <Col lg="4" key={index}>
                                                <BlogItemLayout
                                                      blog={blogItem}
                                                      full="10px"
                                                />
                                          </Col>
                                    ))}
                              </Row>
                              <BannerImage
                                    image="/banners/Group 8.png"
                                    title="Banner"
                              />
                              <CommonHeading
                                    classes="my-3 mt-4"
                                    title={"Fashion"}
                              />
                              <BlogLayout
                                    blogs={fashion}
                                    position={true}
                                    description={true}
                              />
                        </Col>
                        <Col lg="4">
                              <CustomSticky top="10px">
                                    <RegisterBanner />
                                    <CommonHeading
                                          classes="mb-3 mt-4"
                                          title={"Recent Posts"}
                                    />
                                    <BlogWidgetLeft blogs={blognews} />
                                    <BannerImage
                                          image="/banners/Group 10.png"
                                          title="Banner"
                                    />
                                    <CommonHeading
                                          classes="my-3 mt-4"
                                          title={"Lifestyle"}
                                    />
                                    <BlogWidget blogs={fashion} position />
                              </CustomSticky>
                        </Col>
                        <Col lg={12} className="my-3">
                              <BannerImage
                                    image="/banners/Group 11.png"
                                    height="140px"
                                    title="Banner"
                              />
                        </Col>
                        <Col lg={4}>
                              <CommonHeading
                                    classes="my-3"
                                    title={"Comedians"}
                              />
                              {blognews?.slice(0, 3)?.map((item, index) => (
                                    <BlogItemLayout blog={item} key={index} />
                              ))}
                        </Col>
                        <Col lg={4}>
                              <CommonHeading
                                    classes="my-3"
                                    title={"Magicians"}
                              />
                              {entertainment
                                    ?.slice(0, 3)
                                    ?.map((item, index) => (
                                          <BlogItemLayout
                                                blog={item}
                                                key={index}
                                          />
                                    ))}
                        </Col>
                        <Col lg={4}>
                              <CommonHeading
                                    classes="my-3"
                                    title={"Musicians"}
                              />
                              {fashion?.slice(0, 3)?.map((item, index) => (
                                    <BlogItemLayout
                                          blog={item}
                                          key={index}
                                          height="100px"
                                    />
                              ))}
                        </Col>
                  </Row>
            </>
      );
};

export default BlogSection;
