import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { CustomSticky } from "../BlogSection/style";
import CommonHeading from "../../components/CommonHeading";
import BlogWidgetLeft from "../../components/blogs/BlogWidget/BlogWidgetLeft";
import { BannerImage } from "../../components/common";
import { blognews } from "../../data/blogdata";
import { BlogDetailContent, BlogDetailImage, BlogDetailTitle } from "./style";
import {
      BlogItemContent,
} from "../../components/blogs/BlogItemLayout/style";
import CalenderIcon from "../../components/icons/Calender";
import BlogItemLayout from "../../components/blogs/BlogItemLayout";
import axios from "axios";
import { useParams } from "react-router-dom";

const BlogDetail = () => {
      const { blogId } = useParams();
      const [loading, setLoading] = useState(false);
      const [blog, setBlog] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/blogs/${blogId}`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY,
                              },
                        }
                  )
                  .then((response) => {
                        setBlog(response.data.blog);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <Container className="my-5">
                  <Row>
                        <Col lg={8}>
                              <BlogDetailImage>
                                    <img
                                          src={blog.image}
                                          alt={blog.title}
                                          height="100%"
                                          width="100%"
                                    />
                              </BlogDetailImage>
                              <BlogDetailContent>
                                    <BlogItemContent
                                          full="100%"
                                          className="mb-3"
                                    >
                                          <span>
                                                <CalenderIcon classes={"5px"} />
                                                {blog.date}
                                          </span>
                                          <BlogDetailTitle>
                                                {blog.title}
                                          </BlogDetailTitle>
                                    </BlogItemContent>
                                    <div
                                          dangerouslySetInnerHTML={{
                                                __html: blog.description,
                                          }}
                                    >
                                          
                                    </div>
                              </BlogDetailContent>
                        </Col>
                        <Col lg="4">
                              <CustomSticky top="10px">
                                    <CommonHeading
                                          classes="mb-3"
                                          title={"Recent Posts"}
                                    />
                                    <BlogWidgetLeft blogs={blognews} />
                                    <BannerImage
                                          image="/banners/Group 10.png"
                                          title="Banner"
                                    />
                              </CustomSticky>
                        </Col>
                        <Col lg={12}>
                              <CommonHeading
                                    classes="my-3 mt-4"
                                    title={"Related Posts"}
                              />
                              <Row>
                                    {blognews
                                          .slice(0, 3)
                                          .map((blogItem, index) => (
                                                <Col lg="4" key={index}>
                                                      <BlogItemLayout
                                                            blog={blogItem}
                                                            full="10px"
                                                      />
                                                </Col>
                                          ))}
                              </Row>
                        </Col>
                  </Row>
            </Container>
      );
};

export default BlogDetail;
