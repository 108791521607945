import React from "react";
import { BannerContainer } from "./style";

const BannerImage = ({ image, title, height }) => {
      return (
            <>
                  <BannerContainer height={height}>
                        <img
                              src={image}
                              alt={title}
                              height="100%"
                              width="100%"
                        />
                  </BannerContainer>
            </>
      );
};

export default BannerImage;
