import { Link } from "react-router-dom";
import styled from "styled-components";

export const BlogItemImage = styled.div`
      width: ${(props) => (props.full ? "100%" : "180px")};
      overflow: hidden;
      border-radius: 5px;
      height: ${(props) => props.height};

      a {
            display: block;
            width: 100%;

            img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  transition: transform 0.3s linear 0s;

                  &:hover {
                        transform: scale(1.1);
                        transition: transform 0.3s linear 0s;
                  }
            }
      }
`;

export const BlogItemTitle = styled(Link)`
      font-size: 16px;
      color: ${(props) => props.theme.primary};
      display: block;
      transition: all 0.3s ease-in-out;

      &:hover {
            color: ${(props) => props.theme.secondary};
      }
`;

export const BlogItemContent = styled.div`
      width: ${(props) => (props.full ? "100%" : "calc(100% - 190px)")};

      span {
            color: rgba(33, 37, 41, 0.75);
            display: block;
            font-size: 14px;
      }

      p {
            font-size: 14px;
            margin-top: 5px;
            margin-bottom: 0;
      }
`;

export const BlogItemContainer = styled.div`
      display: flex;
      gap: ${(props) => (props.full ? "10px" : "16px")};
      margin-bottom: 20px;
      flex-direction: ${(props) => props.full && "column"};

      &:hover {
            ${BlogItemTitle} {
                  color: ${(props) => props.theme.secondary};
            }
      }
`;
