import React from "react";
import {
      RegisterButton,
      RegisterContainer,
      RegisterContent,
      RegisterTitle,
} from "./style";

const RegisterBanner = () => {
      return (
            <>
                  <RegisterContainer>
                        <img
                              src="/news/6.jpg"
                              alt="Visit our awesome merch and souvenir
                                          online ."
                              width="100%"
                              height="100%"
                        />

                        <RegisterContent>
                              <RegisterTitle>
                                    <p>
                                          Visit our awesome merch and souvenir
                                          online .
                                    </p>
                                    <RegisterButton to="/become-a-artist">
                                          Become a artist
                                    </RegisterButton>
                              </RegisterTitle>
                        </RegisterContent>
                  </RegisterContainer>
            </>
      );
};

export default RegisterBanner;
