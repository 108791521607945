import React, { useEffect, useState } from "react";
import { FooterLogo } from "../footer/style";
import { HeaderNav, HeaderSection, NavLinkContainer } from "./style";
import SubHeader from "../../components/layouts/headers/SubHeader";
import { Container } from "react-bootstrap";
import NavBar from "../../components/layouts/headers/NavBar";
import NavLinkItem from "../../components/layouts/headers/NavLinkItem";
import { MobileMenu } from "../../components/layouts/headers";
import axios from "axios";

export default function Header() {
      const [loading, setLoading] = useState(false);
      const [categories, setCategories] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/blog_categories`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setCategories(response.data.categories);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <SubHeader />
                  <HeaderSection>
                        <Container>
                              <HeaderNav>
                                    <FooterLogo
                                          to="/"
                                          height="100px"
                                          padding={"0"}
                                    >
                                          <img
                                                src="/white-logo.png"
                                                alt="World's Got Talent Logo"
                                                width="100%"
                                                height="100%"
                                          />
                                    </FooterLogo>
                                    <NavLinkContainer>
                                          <NavLinkItem
                                                // icon={<HomeIcon />}
                                                title={"Home"}
                                                slug="/"
                                                padding={"0 15px"}
                                                lineheight="38px"
                                                color="#E4B43C"
                                          />
                                          {!loading
                                                ? categories?.map(
                                                        (item, index) => (
                                                              <NavLinkItem
                                                                    title={
                                                                          item.title
                                                                    }
                                                                    key={index}
                                                                    slug={`/category/${item.slug}`}
                                                                    padding={
                                                                          "0 15px"
                                                                    }
                                                                    color="#E4B43C"
                                                              />
                                                        )
                                                  )
                                                : null}
                                    </NavLinkContainer>
                                    <MobileMenu />
                              </HeaderNav>
                        </Container>
                  </HeaderSection>
                  <NavBar />
            </>
      );
}
