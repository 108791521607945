import { Form, Row } from "react-bootstrap";
import styled from "styled-components";

export const FaqRow = styled(Row)`
      @media screen and (max-width: 768px) {
            --bs-gutter-x: 0;
            --bs-gutter-y: 0;

            .col-lg-4 {
                  &:first-child {
                        order: 2;
                  }

                  &:last-child {
                        order: 1;
                        margin-bottom: 20px;
                  }
            }
      }
`;

export const FaqHeader = styled.div`
      font-size: 36px;
`;

export const FaqForm = styled(Form)`
      margin-top: 2rem;

      .form-label {
            color: rgba(33, 37, 41, 0.75);
      }

      .form-control {
            border-radius: 0;
            border-top: 0;
            border-left: 0;
            border-right: 0;

            &:focus {
                  box-shadow: none;
                  background-color: transparent;
            }
      }
`;

export const FormButton = styled.button`
      background-color: ${(props) => props.theme.primary};
      color: ${(props) => props.theme.white};
      border: 0;
      padding: 10px 20px;
      border-radius: 5px;
      margin-top: 10px;
      transition: all 0.3s ease-in-out;

      &:hover {
            background-color: ${(props) => props.theme.secondary};
      }
`;

export const FaqContent = styled.div`
      margin-bottom: 3rem;

      &:first-child {
            margin-top: 4rem;
      }

      &:last-child {
            margin-bottom: 0;
      }

      @media screen and (max-width: 768px) {
            &:first-child {
                  margin-top: 0;
            }
      }
`;

export const FaqShape = styled.div`
      background-color: #000000;
      border-radius: 50%;
      height: 20px;
      width: 20px;
      margin-right: 10px;
      display: inline-block;
`;

export const FaqTitle = styled.div`
      margin-bottom: 10px;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 22px;
      display: flex;
      align-items: center;

      div:last-child {
            width: calc(100% - 30px);
      }
`;
export const FaqSummary = styled.div`
      font-size: 18px;
      padding-left: 30px;
`;
