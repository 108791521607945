import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import CommonHeading from "../../components/CommonHeading";
import BlogItemLayout from "../../components/blogs/BlogItemLayout";
import { blognews } from "../../data/blogdata";
import { useParams } from "react-router-dom";
import axios from "axios";

const CategoryPage = () => {
      const { categoryId } = useParams();

      const [loading, setLoading] = useState(false);
      const [blogs, setBlogs] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/blog_categories/${categoryId}`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setBlogs(response.data.blogs);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Container>
                        <CommonHeading classes="my-3 mt-4" title={categoryId} />
                        <Row>
                              {blogs.map((blogItem, index) => (
                                    <Col lg="4" key={index}>
                                          <BlogItemLayout
                                                blog={blogItem}
                                                full="10px"
                                          />
                                    </Col>
                              ))}
                        </Row>
                  </Container>
            </>
      );
};

export default CategoryPage;
