import React from "react";
import { BlogContainer } from "../style";
import BlogItemLayout from "../../BlogItemLayout";

const BlogWidgetLeft = ({ blogs }) => {
      const blogTopThree = blogs.slice(0, 3);
      return (
            <BlogContainer size="43%" float="left" margin="8px">
                  {blogTopThree.map((blogItem, index) => (
                        <BlogItemLayout blog={blogItem} key={index} />
                  ))}
            </BlogContainer>
      );
};

export default BlogWidgetLeft;
