import React from "react";

const YoutubeIcon = () => {
      return (
            <>
                  <i className="bx bxl-youtube"></i>
            </>
      );
};

export default YoutubeIcon;
