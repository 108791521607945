import styled from "styled-components";

export const HeaderNav = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
`;

export const HeaderContent = styled.div`
      display: flex;
      justify-content: space-between;
      align-items: center;
`;
export const NavLinkContainer = styled.ul`
      display: flex;
      margin-bottom: 0;
      padding-left: 0;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 1000px) {
            display: none;
      }
`;

export const HeaderContainer = styled.div`
      background-color: ${(props) => props.backgroundColor || "#fff"};
      color: #1f2126;
      border-bottom: ${(props) => props.border || ""};
      border-color: ${(props) => props.borderColor || "#E4B43C"};

      @media screen and (max-width: 1000px) {
            ${NavLinkContainer} {
                  display: flex !important;
            }
      }
`;

export const HeaderSection = styled.div`
      background-color: #1f2126;
`;
