import React from "react";
import { CustomIcon } from "../style";

const SearchIcon = ({ fontSize }) => {
      return (
            <CustomIcon fontSize={fontSize}>
                  <i className="bx bx-search"></i>
            </CustomIcon>
      );
};

export default SearchIcon;
