import styled from "styled-components";

export const CareerContent = styled.div`
      border-bottom: 1px solid #cccccc;
      padding-top: 3rem;
      padding-bottom: 2rem;
`;

export const CareerTitle = styled.div`
      font-size: 46px;
      padding-bottom: 10px;
      font-weight: 400;

      @media screen and (max-width: 768px) {
            font-size: 28px;
      }
`;

export const CareerSummary = styled.p`
      font-size: 18px;
`;

export const CareerItem = styled.div`
      height: 180px;
      background-color: #eeeeee;
      border-bottom: 6px solid #f36a30;
      border-radius: 5px;

      span {
            vertical-align: middle;
            display: table-cell;
            font-size: 20px;
            line-height: 26px;
            color: #2a2a2a;
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            padding: 0px 20px;
            word-break: break-word;
      }
`;

export const EventItem = styled.div`
      margin-bottom: 35px;
`;

export const CareerIntern = styled.div`
      text-align: center;
      background-color: #eeeeee;
      height: ${(props) => (props.height ? props.height : "215px")};
      line-height: 175px;
      margin-bottom: ${(props) => (props.margin ? props.margin : "35px")};
      width: 100%;
      position: relative;
      border-radius: 5px;

      &::before {
            z-index: -1;
            position: absolute;
            content: "";
            bottom: 19px;
            left: 10px;
            width: 50%;
            top: 80%;
            max-width: 300px;
            background: #eee;
            -webkit-box-shadow: 0 16px 16px #777;
            box-shadow: 0 16px 16px #777;
            -webkit-transform: rotate(-4deg);
            -o-transform: rotate(-4deg);
            transform: rotate(-4deg);
      }

      &::after {
            z-index: -1;
            position: absolute;
            content: "";
            bottom: 19px;
            width: 50%;
            top: 80%;
            max-width: 300px;
            background: #eee;
            box-shadow: 0 16px 16px #777;
            transform: rotate(4deg);
            right: 10px;
            left: auto;
      }

      img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 5px;
      }
`;
