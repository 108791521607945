import styled from "styled-components";

export const CustomHeading = styled.h2`
      position: relative;
      font-size: 24px;

      font-weight: 400;
      span {
            margin-left: 16px;
            text-transform: capitalize;
      }
      &::before {
            content: "";
            position: absolute;
            width: 4px;

            height: 100%;
            background-color: #e4b43c;
      }
`;
