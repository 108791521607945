import React from "react";
import { BlogSliderContainer, BlogSliderItem, SwiperBlog } from "./style";
import BlogSlider from "./BlogSlider";
import BlogCollection from "./BlogCollection";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { SwiperSlide } from "swiper/react";

const BlogLists = ({ blogs }) => {
      return (
            <>
                  <BlogSliderContainer>
                        <BlogSliderItem>
                              <SwiperBlog
                                    spaceBetween={30}
                                    centeredSlides={true}
                                    autoplay={{
                                          delay: 3000,
                                          disableOnInteraction: false,
                                    }}
                                    pagination={false}
                                    loop={true}
                                    navigation={false}
                                    modules={[Autoplay, Pagination, Navigation]}
                              >
                                    {blogs.map((blogItem, index) => (
                                          <SwiperSlide key={index}>
                                                <BlogSlider blogs={blogItem} />
                                          </SwiperSlide>
                                    ))}
                              </SwiperBlog>
                        </BlogSliderItem>
                        <BlogSliderItem>
                              <BlogCollection blogs={blogs} />
                        </BlogSliderItem>
                  </BlogSliderContainer>
            </>
      );
};

export default BlogLists;
