import { Offcanvas } from "react-bootstrap";
import styled from "styled-components";

export const MenuButton = styled.div`
      font-size: 24px;
      cursor: pointer;
      display: none;

      @media screen and (max-width: 1000px) {
            display: block;

            i {
                  color: ${(props) => props.theme.white};
            }
      }
`;

export const MenuContent = styled(Offcanvas)`
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-top: 5px solid ${(props) => props.theme.primary};

      .offcanvas-header {
            background-color: ${(props) => props.theme.primary};
            padding-top: 3px;
            padding-bottom: 3px;

            .btn-close {
                  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>");
            }

            img {
                  height: 60px;
                  object-fit: contain;
            }
      }

      .offcanvas-body {
            padding: 0;
      }
`;

export const MobileMenuContent = styled.ul`
      padding: 0;

      li {
            border-bottom: 1px solid #e4e4e4;

            &:first-child {
                  border-top: 1px solid #e4e4e4;
            }
            a {
                  line-height: 1;
                  display: block;
                  padding: 15px 20px;
                  color: #666666;
                  text-decoration: none;
                  display: flex;
                  align-items: center;
                  text-transform: uppercase;
            }
      }
`;
