import React from "react";
import {
      Button,
      Col,
      Container,
      Form,
      FormControl,
      FormLabel,
      Row,
} from "react-bootstrap";
import {
      FaqContent,
      FaqForm,
      FaqHeader,
      FaqRow,
      FaqShape,
      FaqSummary,
      FaqTitle,
      FormButton,
} from "./style";

const FaqPage = () => {
      return (
            <>
                  <Container className="my-5">
                        <FaqRow className="g-5">
                              <Col lg={4}>
                                    <FaqHeader>FAQ</FaqHeader>
                                    <FaqForm>
                                          <Row className="g-3">
                                                <Col lg={12}>
                                                      <FormLabel>
                                                            Full Name
                                                      </FormLabel>
                                                      <FormControl />
                                                </Col>
                                                <Col lg={12}>
                                                      <FormLabel>
                                                            Email
                                                      </FormLabel>
                                                      <FormControl />
                                                </Col>
                                                <Col lg={12}>
                                                      <FormLabel>
                                                            Mobile No.
                                                      </FormLabel>
                                                      <FormControl />
                                                </Col>
                                                <Col lg={12}>
                                                      <FormLabel>
                                                            Query
                                                      </FormLabel>
                                                      <FormControl
                                                            as="textarea"
                                                            rows={4}
                                                      />
                                                </Col>
                                                <Col lg={12}>
                                                      <FormButton>
                                                            Send Message
                                                      </FormButton>
                                                </Col>
                                          </Row>
                                    </FaqForm>
                              </Col>
                              <Col lg={8}>
                                    <FaqContent>
                                          <FaqTitle>
                                                <FaqShape />
                                                <div>
                                                      WHEN WILL I RECIEVE MY
                                                      FIRST ISSUE?
                                                </div>
                                          </FaqTitle>
                                          <FaqSummary>
                                                Lorem ipsum dolor sit amet,
                                                consectetur a elit. In ut
                                                ullamcorper leo, eget euismod
                                                orci. Cum sociis natoque penibus
                                                magnis dis parturient montes,
                                                nascetur ridiculus musbulum
                                                ultricies aliquam convallis.
                                                Maecenas ut tellus mi incidunt
                                                ultricies lectus eu volutpat
                                                mattis, ante metus lacinia
                                                tellus, vitae condimentum nulla
                                                enim bibendum nibh. Praesent
                                                vel, tempo molestie nibh. In hac
                                                habitasse platea.
                                          </FaqSummary>
                                    </FaqContent>

                                    <FaqContent>
                                          <FaqTitle>
                                                <FaqShape />
                                                <div>
                                                      CAN I SEND A MAGAZINE
                                                      SUBSCRIPTION AS A GIFT?
                                                </div>
                                          </FaqTitle>
                                          <FaqSummary>
                                                Lorem ipsum dolor sit amet,
                                                consectetur a elit. In ut
                                                ullamcorper leo, eget euismod
                                                orci. Cum sociis natoque penibus
                                                magnis dis parturient montes,
                                                nascetur ridiculus musbulum
                                                ultricies aliquam convallis.
                                                Maecenas ut tellus mi incidunt
                                                ultricies lectus eu volutpat
                                                mattis, ante metus lacinia
                                                tellus, vitae condimentum nulla
                                                enim bibendum nibh. Praesent
                                                vel, tempo molestie nibh. In hac
                                                habitasse platea.
                                          </FaqSummary>
                                    </FaqContent>
                                    <FaqContent>
                                          <FaqTitle>
                                                <FaqShape />
                                                <div>
                                                      CAN I SEND A MAGAZINE
                                                      SUBSCRIPTION AS A GIFT?
                                                </div>
                                          </FaqTitle>
                                          <FaqSummary>
                                                Lorem ipsum dolor sit amet,
                                                consectetur a elit. In ut
                                                ullamcorper leo, eget euismod
                                                orci. Cum sociis natoque penibus
                                                magnis dis parturient montes,
                                                nascetur ridiculus musbulum
                                                ultricies aliquam convallis.
                                                Maecenas ut tellus mi incidunt
                                                ultricies lectus eu volutpat
                                                mattis, ante metus lacinia
                                                tellus, vitae condimentum nulla
                                                enim bibendum nibh. Praesent
                                                vel, tempo molestie nibh. In hac
                                                habitasse platea.
                                          </FaqSummary>
                                    </FaqContent>
                                    <FaqContent>
                                          <FaqTitle>
                                                <FaqShape />
                                                <div>HOW MUCH IS DELIVERY?</div>
                                          </FaqTitle>
                                          <FaqSummary>
                                                Lorem ipsum dolor sit amet,
                                                consectetur a elit. In ut
                                                ullamcorper leo, eget euismod
                                                orci. Cum sociis natoque penibus
                                                magnis dis parturient montes,
                                                nascetur ridiculus musbulum
                                                ultricies aliquam convallis.
                                                Maecenas ut tellus mi incidunt
                                                ultricies lectus eu volutpat
                                                mattis, ante metus lacinia
                                                tellus, vitae condimentum nulla
                                                enim bibendum nibh. Praesent
                                                vel, tempo molestie nibh. In hac
                                                habitasse platea.
                                          </FaqSummary>
                                    </FaqContent>
                              </Col>
                        </FaqRow>
                  </Container>
            </>
      );
};

export default FaqPage;
