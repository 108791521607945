import React from "react";
import { BlogLeftContainer } from "./style";
import BlogItemLayout from "../BlogItemLayout";
import { Col, Row } from "react-bootstrap";

const BlogLayout = ({ blogs, position, description }) => {
      const blogsTopThree = blogs.slice(-3);
      return (
            <>
                  <BlogLeftContainer>
                        <Row>
                              {!position && (
                                    <Col lg={6}>
                                          <BlogItemLayout
                                                blog={blogs[0]}
                                                full="10px"
                                                description={description}
                                          />
                                    </Col>
                              )}
                              <Col lg={6}>
                                    {blogsTopThree?.map((item, index) => (
                                          <BlogItemLayout
                                                blog={item}
                                                key={index}
                                          />
                                    ))}
                              </Col>
                              {position && (
                                    <Col lg={6}>
                                          <BlogItemLayout
                                                blog={blogs[0]}
                                                full="10px"
                                                description={description}
                                          />
                                    </Col>
                              )}
                        </Row>
                  </BlogLeftContainer>
            </>
      );
};

export default BlogLayout;
