import styled from "styled-components";

export const SliderItemContainer = styled.div`
      position: relative;
      z-index: 5;
      border-radius: 5px;
      height: 100%;

      img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
      }
      span {
            font-size: 12px;
            margin-right: 8px;
      }
      h4 {
            margin-top: 9px;
            font-size: 18px;
            font-weight: 400;
      }
`;

export const SliderItemContent = styled.div`
      padding: 15px;
      position: absolute;
      width: 100%;
      bottom: 0px;
      color: #fff;
      background: rgba(0, 0, 0, 0.5);
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
`;
