export const blognews = [
      {
            title: "10 Trendy Outfit Ideas for Spring 2024",
            image: "/events/4504986_1920x1080_4.jpg",

            author: "Fashionista Magazine",
            date: "March 10, 2024",
            description:
                  "Discover the latest spring fashion trends and get inspired with these stylish outfit ideas for the season.Discover the latest spring fashion trends and get inspired with these stylish outfit ideas for the season.Discover the latest spring fashion trends and get inspired with these stylish outfit ideas for the season.Discover the latest spring fashion trends and get inspired with these stylish outfit ideas for the season.Discover the latest spring fashion trends and get inspired with these stylish outfit ideas for the season.Discover the latest spring fashion trends and get inspired with these stylish outfit ideas for the season.",
      },
      {
            title: "Interview with Top Fashion Designer: Behind the Scenes",
            image: "/events/4504990_1920x1080_0.jpg",

            author: "Fashion Insider",
            date: "February 28, 2024",
            description:
                  "Get an exclusive look into the creative process of a renowned fashion designer and learn about their latest collection.",
      },
      {
            title: "Interview with Top Fashion Designer: Behind the Scenes",
            image: "/events/4504994_1920x1080.jpg",

            author: "Fashion Insider",
            date: "February 28, 2024",
            description:
                  "Get an exclusive look into the creative process of a renowned fashion designer and learn about their latest collection.",
      },
      {
            title: "Interview with Top Fashion Designer: Behind the Scenes",
            image: "/events/4505044_1920x1080.jpg",

            author: "Fashion Insider",
            date: "February 28, 2024",
            description:
                  "Get an exclusive look into the creative process of a renowned fashion designer and learn about their latest collection.",
      },
      {
            title: "Interview with Top Fashion Designer: Behind the Scenes",
            image: "/events/4505043_1920x1080.jpg",

            author: "Fashion Insider",
            date: "February 28, 2024",
            description:
                  "Get an exclusive look into the creative process of a renowned fashion designer and learn about their latest collection.",
      },
      {
            title: "Interview with Top Fashion Designer: Behind the Scenes",
            image: "/events/4504990_1920x1080_0.jpg",

            author: "Fashion Insider",
            date: "February 28, 2024",
            description:
                  "Get an exclusive look into the creative process of a renowned fashion designer and learn about their latest collection.",
      },
];
export const entertainment = [
      {
            title: "Top 10 Hits of 2024",
            image: "/trendings/011024_tsjffep_10.jpg",
            author: "Music World",
            date: "April 5, 2024",
            description:
                  "Explore the top music hits of the year 2024 and find your new favorite songs.",
      },
      {
            title: "Greatest Hits of the Decade",
            image: "/trendings/110924_lnsmfep_13.jpg",
            author: "Music Legends",
            date: "June 15, 2024",
            description:
                  "Take a trip down memory lane with the greatest hits from the past decade.",
      },
      {
            title: "Chart-Toppers: Summer Edition",
            image: "/trendings/LAB_FullEp_010924_16.jpg",
            author: "Summer Vibes",
            date: "July 20, 2024",
            description:
                  "Get into the summer groove with these chart-topping hits that will keep you dancing all season long.",
      },
      {
            title: "Classic Rock Anthems",
            image: "/trendings/NightCourt-FormFetish-Thumbnail_16.jpg",
            author: "Rock Legends",
            date: "August 10, 2024",
            description:
                  "Rediscover the timeless classics with this collection of rock anthems that defined a generation.",
      },
      {
            title: "Pop Sensations: Rising Stars",
            image: "/trendings/LAB_FullEp_010924_16.jpg",
            author: "Pop Sensations",
            date: "September 5, 2024",
            description:
                  "Discover the hottest new talents in the pop music scene with these rising stars and their catchy tunes.",
      },
      {
            title: "Jazz Essentials: Smooth Sounds",
            image: "/trendings/011024_tsjffep_10.jpg",
            author: "Smooth Jazz",
            date: "October 15, 2024",
            description:
                  "Immerse yourself in the smooth sounds of jazz with this essential collection of jazz classics.",
      },
      {
            title: "Electronic Beats: EDM Party",
            image: "/trendings/NightCourt-FormFetish-Thumbnail_16.jpg",
            author: "EDM Vibes",
            date: "November 20, 2024",
            description:
                  "Get ready to party with the latest electronic beats and EDM tracks that will electrify your senses.",
      },
      {
            title: "Country Road Trip: Country Hits",
            image: "/trendings/LAB_FullEp_010924_16.jpg",
            author: "Country Roads",
            date: "December 10, 2024",
            description:
                  "Hit the road with these country hits that will make your journey memorable and full of twangy tunes.",
      },
      {
            title: "Electronic Beats: EDM Party",
            image: "/trendings/NightCourt-FormFetish-Thumbnail_16.jpg",
            author: "EDM Vibes",
            date: "November 20, 2024",
            description:
                  "Get ready to party with the latest electronic beats and EDM tracks that will electrify your senses.",
      },
      {
            title: "Country Road Trip: Country Hits",
            image: "/trendings/LAB_FullEp_010924_16.jpg",
            author: "Country Roads",
            date: "December 10, 2024",
            description:
                  "Hit the road with these country hits that will make your journey memorable and full of twangy tunes.",
      },
];

export const fashion = [
      {
            title: "10 Trendy Outfit Ideas for Spring 2024",
            image: "/fashions/58594_JKU_VogueHolidayGiftGuide_011_13_V2_9X16.jpg",
            author: "Fashionista Magazine",
            date: "March 10, 2024",
            description:
                  "Discover the latest spring fashion trends and get inspired with these stylish outfit ideas for the season.",
      },
      {
            title: "Interview with Top Fashion Designer: Behind the Scenes",
            image: "/fashions/195-24B KMC_VOG KM_VOGUE_STOREFRONT_LOOK_04_HERO_168.jpg",
            author: "Fashion Insider",
            date: "February 28, 2024",
            description:
                  "Get an exclusive look into the creative process of a renowned fashion designer and learn about their latest collection. From concept to creation, follow the journey of designing a high-fashion runway show and gain insights into the world of haute couture.",
      },
      {
            title: "10 Trendy Outfit Ideas for Spring 2024",
            image: "/fashions/HFA_1309.jpg",
            author: "Fashionista Magazine",
            date: "March 10, 2024",
            description:
                  "Discover the latest spring fashion trends and get inspired with these stylish outfit ideas for the season.",
      },
      {
            title: "Red Carpet Glamour: Best Dressed Celebrities",
            image: "/fashions/Katie_McCurdy_VOGUE-52.jpg",
            author: "Red Carpet Magazine",
            date: "January 15, 2024",
            description:
                  "Take a front-row seat at the hottest red carpet events and admire the stunning looks of your favorite celebrities. From Hollywood starlets to international icons, see who stole the spotlight with their impeccable style.",
      },
      {
            title: "DIY Fashion: Creative Ways to Upcycle Your Wardrobe",
            image: "/fashions/58594_JKU_VogueHolidayGiftGuide_011_13_V2_9X16.jpg",
            author: "Fashion DIY",
            date: "August 8, 2024",
            description:
                  "Transform your old clothes into fashion-forward pieces with these innovative DIY ideas. From customizing denim jackets to embellishing t-shirts, unleash your creativity and give your wardrobe a stylish makeover.",
      },
      {
            title: "Vintage Fashion: Timeless Styles to Inspire Your Look",
            image: "/fashions/195-24B KMC_VOG KM_VOGUE_STOREFRONT_LOOK_04_HERO_168.jpg",
            author: "Vintage Vibes",
            date: "May 1, 2024",
            description:
                  "Explore the charm of vintage fashion and discover timeless styles that never go out of style. From elegant silhouettes to retro prints, embrace the nostalgia and add a touch of vintage flair to your wardrobe.",
      },
      {
            title: "Street Style Trends: Fashionistas Around the World",
            image: "/fashions/HFA_1309.jpg",
            author: "Street Chic",
            date: "July 10, 2024",
            description:
                  "Get inspired by the fashionistas who rule the streets with their impeccable style. From New York to Tokyo, discover the latest street style trends and learn how to incorporate them into your own wardrobe.",
      },
      {
            title: "Fashion Forward: Emerging Designers to Watch",
            image: "/fashions/58594_JKU_VogueHolidayGiftGuide_011_13_V2_9X16.jpg",
            author: "Fashion Futures",
            date: "September 30, 2024",
            description:
                  "Discover the next generation of fashion talent with these emerging designers who are making waves in the industry. From avant-garde visionaries to eco-conscious creators, meet the designers who are shaping the future of fashion.",
      },
      {
            title: "Wedding Season Style: Bridal Fashion Inspiration",
            image: "/fashions/GettyImages-1465202865.jpg",
            author: "Bridal Bliss",
            date: "April 15, 2024",
            description:
                  "Find the perfect bridal look for your special day with these stunning wedding season style ideas. From classic elegance to modern romance, discover the latest trends in bridal fashion and make your wedding day truly unforgettable.",
      },
];
